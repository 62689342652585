import { container } from "assets/jss/material-kit-react.jsx";

const trainingPageStyle = {
  container,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "90px 0px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    padding: "20px 0",
  },
  ribbon: {
    background: "rgba(10,10,10,0.9)",
    width: "100%",
    position: "absolute",
    left: "0",
    right: "0",
    top: "100px",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "@media screen and (min-width: 600px)": {
      //background: "rgba(200,10,10,0.7)",
      top: "300px",
    },
    // borderTop: "1px solid #dd0000",
    // borderBottom: "1px solid #dd0000"
  },
  brand: {
    color: "#FFFFFF",
    textAlign: "left",
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative",
    marginTop: "0",
  },
  subtitle: {
    fontSize: "1.313rem",
    //maxWidth: "500px",
    margin: "10px 0 0",
    "@media screen and (max-width: 600px)": {
      //background: "rgba(200,10,10,0.7)",
      fontSize: "1rem",
    },
    "& p": {
      margin: "inherit",
      fontSize: "inherit",
    },
  },
};

export default trainingPageStyle;

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Carousel from "react-slick";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";

import { getEntry, getEntries } from "lib/api";

import aboutUsStyle from "assets/jss/material-kit-react/views/componentsSections/aboutUsStyle.jsx";

class SectionAboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pageInformation: null, imageCarousel: null };
  }

  async componentDidMount() {
    const pageInformation = await getEntry("7trawZP5NAHeODzqcirSCn");
    const imageCarousel = await getEntries({
      content_type: "aboutUsImages",
    });

    this.setState({ pageInformation, imageCarousel });
  }

  render() {
    const { classes } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h2>{this.state.pageInformation?.title}</h2>
          </div>

          <div id="about-us">
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                {this.state.pageInformation?.description}
                <h2>Join Now!</h2>
                <Button
                  color="primary"
                  size="lg"
                  href="https://www.entrycentral.com/club/103575"
                  target="blank"
                  rel="noreferrer"
                >
                  <i className="fas fa-user" />
                  <span style={{ marginLeft: "10px" }}>Adult</span>
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Card carousel style={{ margin: "0" }}>
                  <Carousel {...settings}>
                    {this.state.imageCarousel?.map((carouselItem) => (
                      <img
                        src={carouselItem?.image.url}
                        key={carouselItem?.name}
                        alt={carouselItem?.name}
                        className="slick-image"
                      />
                    ))}

                    {/* <img src={gordo} alt="Gordon" className="slick-image" />

                    <img src={alex} alt="Alex" className="slick-image" />

                    <img src={dive} alt="Diving" className="slick-image" />

                    <img src={team} alt="Team" className="slick-image" /> */}
                  </Carousel>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

SectionAboutUs.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(aboutUsStyle)(SectionAboutUs);

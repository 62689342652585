import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { getEntry } from "lib/api";

import duathlonStyle from "assets/jss/material-kit-react/views/componentsSections/Events/duathlonStyle.jsx";

class SectionDuathlon extends React.Component {
  constructor(props) {
    super(props);
    this.state = { duathlon: null };
  }

  async componentDidMount() {
    const duathlon = await getEntry("3YPBJ47KcPAKarqL2MEoip");
    this.setState({ duathlon });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <img
            src={this.state.duathlon?.image.url}
            alt={this.state.duathlon?.image.description}
            className={
              classes.imgRaised +
              " " +
              classes.imgRounded +
              " " +
              classes.imgFluid
            }
          />
          <div className={classes.duathlonBody}>
            <div className={classes.title}>
              <h2>{this.state.duathlon?.title}</h2>
            </div>
            <div className={classes.duathlonDate}>
              <h5>
                {new Date(this.state.duathlon?.date).toLocaleDateString(
                  "en-GB",
                  {
                    dateStyle: "long",
                  }
                )}
              </h5>
            </div>
            <div className={classes.duathlonText}>
              {this.state.duathlon?.description}
            </div>
          </div>
          <GridContainer justify="space-between" className={classes.buttons}>
            <Button
              color="primary"
              size="lg"
              href={this.state.duathlon?.entryLink}
              target="blank"
              rel="noreferrer"
              disabled={!this.state.duathlon?.entriesOpen}
            >
              <i className="fas fa-running" />
              <i className="fas fa-biking" />
              <i className="fas fa-running" />
              <span style={{ marginLeft: "10px" }}>
                {" "}
                {this.state.duathlon?.entriesOpen
                  ? "Entries Open"
                  : "Entries Closed"}
              </span>
            </Button>
          </GridContainer>
        </div>
      </div>
    );
  }
}

SectionDuathlon.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(duathlonStyle)(SectionDuathlon);

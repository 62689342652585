import { createClient } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const client = createClient({
  space: "dron0ki3e81q",
  accessToken: "na7GBdxt6exoKuLdzYlF93xVj60bdzWrrjm_z0vbxb0",
});

export const getAsset = async (id) => {
  const response = await client.getAsset(id);
  const sanitizedItem = sanitizeItem(response);
  return sanitizedItem;
};

export const getEntry = async (id) => {
  try {
    const response = await client.getEntry(id);
    const sanitizedItem = sanitizeItem(response);

    return sanitizedItem;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getEntries = async (query) => {
  try {
    const response = await client.getEntries(query);

    const rawItems = response.items;

    const sanitizedItems = rawItems.map((item) => {
      return sanitizeItem(item);
    });

    return sanitizedItems;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const sanitizeItem = (item) => {
  const sanitizedFields = {};
  const fields = item.fields;
  Object.keys(fields).forEach((key) => {
    const field = fields[key];
    if (field && field.nodeType && field.nodeType === "document") {
      sanitizedFields[key] = documentToReactComponents(field);
    } else if (field && field.sys && field.sys.type === "Asset") {
      sanitizedFields[key] = {
        url: field.fields.file.url,
        title: field.fields.title || "",
        description: field.fields.description || "",
        contentType: field.fields.file.contentType || "",
        fileSize: field.fields.file.details.size || 0,
      };
    } else {
      sanitizedFields[key] = field;
    }
  });
  return sanitizedFields;
};

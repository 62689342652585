/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Fragment } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SocialLinks from "components/Header/SocialLinks.jsx";
import SectionAboutUs from "./Sections/SectionAboutUs.jsx";

import { getEntry } from "lib/api.js";

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";

class Components extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pageInformation: null };
  }

  async componentDidMount() {
    const pageInformation = await getEntry("1KScmhSSfG2Hxg6TzLudqB");
    this.setState({ pageInformation });
  }

  render() {
    const { classes, ...rest } = this.props;
    let brandStyle = {
      color: "#dd0000",
      fontWeight: "bold",
    };

    return (
      <div>
        <Header
          brand={
            <Fragment>
              <span>SUNCITY</span>
              <span style={{ ...brandStyle }}>TRI</span>
            </Fragment>
          }
          leftLinks={<SocialLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          color="dark"
          changeColorOnScroll={{
            height: 700,
            color: "white",
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/Roker3LowerQuality.webp")}>
          <div className={classes.ribbon}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem>
                  <div className={classes.brand}>
                    <h1 className={classes.title}>
                      {this.state.pageInformation?.title}
                    </h1>
                    <h3 className={classes.subtitle}>
                      {this.state.pageInformation?.description}
                    </h3>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <SectionAboutUs />
        </div>
        <Footer />
      </div>
    );
  }
}

Components.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(componentsStyle)(Components);

import {
  container,
  card,
  cardTitle,
  title,
} from "assets/jss/material-kit-react.jsx";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";
const trainingTableStyle = {
  sections: {
    width: "100%",

    padding: "0 15px",
  },
  root: {
    overflowX: "auto",
    "& p": {
      margin: "inherit",
      fontSize: "inherit",
    },
  },

  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
  },
  card: {
    ...card,
    display: "flex",
    flex: 1,
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d",
  },
  table: {
    //    minWidth: 1080
  },
  subTable: {
    //margin: "15px",
    border: "solid 1px black",
  },
  tableRow: {
    "&:nth-child(odd)": {
      background: "#EEEEEE",
      // boxShadow: "0px 0px 3px 0px rgba(50,50,50,1)"
    },
    "&:nth-child(even)": {
      background: "#FFFFFF",
      // boxShadow: "0px 5px 3px 0px rgba(50,50,50,1)"
    },
    // "&>td:first-child": {
    //   fontWeight: "bold"
    // }
  },
  header: {
    width: "100px",
    fontWeight: "bold",
    textAlign: "right",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    "&:first-of-type": {
      margin: "90px 0px 20px 0px",
    },
    margin: "20px 0px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    padding: "20px 0",
  },
  container: {
    ...container,
    margin: "-150px 30px 0px",
    // "& > div:first-child":{
    //   mainRaised: {
    //     margin: "90px 0px 20px 0px"

    //   }
    // }
  },
};

export default trainingTableStyle;

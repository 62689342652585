/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { container } from "assets/jss/material-kit-react.jsx";

const componentsStyle = {
  container,
  ribbon: {
    background: "rgba(10,10,10,0.2)",
    width: "100%",
    position: "absolute",
    left: "0",
    right: "0",
    top: "200px",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "@media screen and (min-width: 600px)": {
      //background: "rgba(200,10,10,0.7)",
      top: "510px",
    },
    // borderTop: "1px solid #dd0000",
    // borderBottom: "1px solid #dd0000"
  },
  brand: {
    color: "#FFFFFF",
    textAlign: "left",
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative",
    marginTop: "0",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px 0 0",
    "& p": {
      margin: "inherit",
      fontSize: "inherit",
    },
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  link: {
    textDecoration: "none",
  },
  textCenter: {
    textAlign: "center",
  },
};

export default componentsStyle;

import React, { Fragment } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import classNames from "classnames";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SocialLinks from "components/Header/SocialLinks.jsx";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import resultsStyle from "assets/jss/material-kit-react/views/resultsStyle.jsx";

import { getEntries } from "lib/api";

class ResultsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pageInformation: null };
  }

  async componentDidMount() {
    const results = await getEntries({
      content_type: "results",
    });

    results.sort((a, b) => b.year - a.year);

    this.setState({ results });
  }

  render() {
    const { classes, ...rest } = this.props;

    const { results } = this.state;
    const renderedResults = results?.map((result) => {
      return (
        <TableRow key={result?.year} className={classes.tableRow}>
          {result?.duathlonResultsLink || result?.duathlonResultsFile ? (
            <TableCell style={{ textAlign: "center" }}>
              <a
                href={
                  result?.duathlonResultsLink
                    ? result?.duathlonResultsLink
                    : result?.duathlonResultsFile.url
                }
                target="blank"
                rel="noreferrer"
              >
                <i className="far fa-list-alt" />
                &nbsp;{result.year} Duathlon
              </a>
            </TableCell>
          ) : (
            <TableCell />
          )}
          {result?.triathlonResultsLink || result?.triathlonResultsFile ? (
            <TableCell style={{ textAlign: "center" }}>
              <a
                href={
                  result?.triathlonResultsLink
                    ? result?.triathlonResultsLink
                    : result?.triathlonResultsFile.url
                }
                target="blank"
                rel="noreferrer"
              >
                <i className="far fa-list-alt" />
                &nbsp;{result.year} Triathlon
              </a>
            </TableCell>
          ) : (
            <TableCell />
          )}
        </TableRow>
      );
    });

    let brandStyle = {
      color: "#dd0000",
      fontWeight: "bold",
    };
    return (
      <Fragment>
        <Header
          brand={
            <Fragment>
              <span>SUNCITY</span>
              <span style={{ ...brandStyle }}>TRI</span>
            </Fragment>
          }
          leftLinks={<SocialLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          color="dark"
          changeColorOnScroll={{
            height: 1,
            color: "white",
          }}
          {...rest}
        />

        <div className={classes.container} style={{ marginTop: "0px" }}>
          <GridContainer justify="center">
            <GridItem xs={8} sm={6} md={6}>
              <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.resultsBody}>
                  <h2 style={{ textAlign: "center", marginTop: "30px" }}>
                    Results
                  </h2>

                  <Table
                    className={classes.table}
                    style={{ tableLayout: "auto" }}
                  >
                    <TableBody style={{ textAlign: "center", align: "center" }}>
                      {renderedResults}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

ResultsPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(resultsStyle)(ResultsPage);

import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Hidden from "@material-ui/core/Hidden";

import Button from "components/CustomButtons/Button.jsx";

// import { Home } from "@material-ui/icons";

// import LinkButton from "components/LinkButton/LinkButton.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function SocialLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.socialListItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/groups/186311948097060/"
            target="_blank"
            className={classes.socialNavLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
            <Hidden mdUp>
              <span> Facebook</span>
            </Hidden>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.socialListItem}>
        <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/trisuncity"
            target="_blank"
            color="transparent"
            className={classes.socialNavLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
            <Hidden mdUp>
              <span> Twitter</span>
            </Hidden>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.socialListItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/suncity_tri"
            target="_blank"
            className={classes.socialNavLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />{" "}
            <Hidden mdUp>
              <span> Instagram</span>
            </Hidden>
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(SocialLinks);

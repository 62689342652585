import React, { Fragment } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// nodejs library that concatenates classes
import classNames from "classnames";

import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import coachStyle from "assets/jss/material-kit-react/components/coachStyle.jsx";

function Coach({ ...props }) {
  const {
    classes,
    coachName,
    title,
    imageSource,
    involvementYear,
    favouriteKit,
    topTip,
    favouritePastTime,
    favouriteDiscipline,
  } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid,
    classes.bordered
  );
  return (
    <Card plain className={classes.coach}>
      <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
        <img src={imageSource} alt={coachName} className={imageClasses} />
      </GridItem>
      <h4 className={classes.cardTitle}>
        {coachName}
        <br />
        <small className={classes.smallTitle}>{title}</small>
      </h4>
      <CardBody>
        <div className={classes.description}>
          {involvementYear && (
            <Fragment>
              <div className={classes.question}>
                What year did you become involved in triathlon?
              </div>
              <div className={classes.answer}>{involvementYear}</div>
            </Fragment>
          )}

          {favouriteKit && (
            <Fragment>
              <div className={classes.question}>
                What is your favorite piece of kit?
              </div>
              <div className={classes.answer}>{favouriteKit}</div>
            </Fragment>
          )}

          {topTip && (
            <Fragment>
              <div className={classes.question}>
                What’s your top triathlon tip?
              </div>
              <div className={classes.answer}>{topTip}</div>
            </Fragment>
          )}

          {favouritePastTime && (
            <Fragment>
              <div className={classes.question}>
                What’s your favorite pastime when not doing triathlon?
              </div>
              <div className={classes.answer}>{favouritePastTime}</div>
            </Fragment>
          )}

          {favouriteDiscipline && (
            <Fragment>
              <div className={classes.question}>
                If you had to pick a favorite discipline what would it be?
              </div>
              <div className={classes.answer}>{favouriteDiscipline}</div>
            </Fragment>
          )}
        </div>
      </CardBody>
      {/* <CardFooter className={classes.justifyCenter}>
      <Button
        justIcon
        color="transparent"
        className={classes.margin5}
      >
        <i className={classes.socials + " fab fa-twitter"} />
      </Button>
      <Button
        justIcon
        color="transparent"
        className={classes.margin5}
      >
        <i className={classes.socials + " fab fa-linkedin"} />
      </Button>
    </CardFooter> */}
    </Card>
  );
}

Coach.defaultProps = {
  color: "gray",
  title: "Coach",
};

Coach.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  children: PropTypes.node,
};

export default withStyles(coachStyle)(Coach);

import React, { Fragment } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SocialLinks from "components/Header/SocialLinks.jsx";

import SectionTriathlon from "views/Components/Sections/Events/SectionTriathlon.jsx";
import SectionDuathlon from "../Components/Sections/Events/SectionDuathlon.jsx";

import eventsPageStyle from "assets/jss/material-kit-react/views/eventsPage.jsx";

class EventsPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    let brandStyle = {
      color: "#dd0000",
      fontWeight: "bold",
    };
    return (
      <div>
        <Header
          brand={
            <Fragment>
              <span>SUNCITY</span>
              <span style={{ ...brandStyle }}>TRI</span>
            </Fragment>
          }
          leftLinks={<SocialLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          color="dark"
          changeColorOnScroll={{
            height: 700,
            color: "white",
          }}
          {...rest}
        />
        <div className={classes.container} style={{ marginTop: "75px" }}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6} className={classes.flex}>
              <div
                className={classNames(classes.main, classes.mainRaised)}
                style={{ marginTop: "15px" }}
              >
                <SectionDuathlon />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} className={classes.flex}>
              <div
                className={classNames(classes.main, classes.mainRaised)}
                style={{ marginTop: "15px" }}
              >
                <SectionTriathlon />
              </div>
            </GridItem>
          </GridContainer>
        </div>

        <Footer />
      </div>
    );
  }
}

EventsPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(eventsPageStyle)(EventsPage);

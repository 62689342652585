import { cardTitle } from "assets/jss/material-kit-react.jsx";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";
import { primaryColor } from "assets/jss/material-kit-react.jsx";

const coachStyle = {
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d",
  },
  ...imagesStyle,
  bordered: {
    border: `2px solid ${primaryColor}`,
    marginLeft: "auto",
    marginRight: "auto",
  },
  description: {
    color: "#999",
  },
  justifyCenter: {
    justifyContent: "center !important",
  },
  question: {
    fontWeight: "bold",
    color: "#888",
  },
  answer: {
    color: "#999",
    fontWeight: "400",
    "& p": {
      margin: "inherit",
      fontSize: "inherit",
    },
  },
};

export default coachStyle;

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
//import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
// import Header from "components/Header/Header.jsx";
// import Footer from "components/Footer/Footer.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
// import GridItem from "components/Grid/GridItem.jsx";

import LinkButton from "components/LinkButton/LinkButton.jsx";
import { Home } from "@material-ui/icons";

import NotFoundPageStyle from "assets/jss/material-kit-react/views/errorPages/notFoundPage.jsx";

class NotFoundPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <h1>404</h1>
        <p>
          <strong>Page not found</strong>
        </p>
        <LinkButton color="gray" className={classes.navLink} to="/">
          <Home className={classes.icons} /> Home
        </LinkButton>
      </div>
    );
  }
}

NotFoundPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(NotFoundPageStyle)(NotFoundPage);

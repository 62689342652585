import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { getEntries } from "lib/api";

import trainingTableStyle from "assets/jss/material-kit-react/views/trainingSections/trainingTableStyle.jsx";

class SectionTrainingTable extends React.Component {
  daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  constructor(props) {
    super(props);
    this.state = { days: [] };
  }
  async componentDidMount() {
    const days = await getEntries({
      content_type: "trainingSession",
    });

    days.sort((a, b) => {
      const dayA = this.daysOfWeek.indexOf(a.day);
      const dayB = this.daysOfWeek.indexOf(b.day);
      return dayA - dayB;
    });

    this.setState({ days });
  }

  render() {
    const { classes } = this.props;

    const rows = this.state.days ? this.state.days : [];
    return (
      <div className={classes.container}>
        {rows.map((row) => (
          <div
            className={classNames(classes.main, classes.mainRaised)}
            key={row.day}
          >
            <div className={classes.sections}>
              <div className={classes.root}>
                <h3 style={{ textAlign: "center" }}>{row.day}</h3>

                <Table
                  className={classes.table}
                  //fixedheader={false}
                  style={{ tableLayout: "auto" }}
                >
                  <TableBody>
                    <TableRow className={classes.tableRow}>
                      <TableCell component="th" className={classes.header}>
                        <b>Session</b>
                      </TableCell>
                      <TableCell>{row.session}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell component="th" className={classes.header}>
                        Start:
                      </TableCell>
                      <TableCell>{row.start}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell component="th" className={classes.header}>
                        Finish:
                      </TableCell>
                      <TableCell>{row.finish}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell component="th" className={classes.header}>
                        Location:
                      </TableCell>
                      <TableCell>{row.location}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell component="th" className={classes.header}>
                        Information:
                      </TableCell>
                      <TableCell>{row.information}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        ))}
        {/* </div>
        </div> */}
      </div>
    );
  }
}

SectionTrainingTable.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(trainingTableStyle)(SectionTrainingTable);

import React, { Fragment } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SocialLinks from "components/Header/SocialLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import { getEntry } from "lib/api";

import SectionTrainingTable from "views/TrainingPage/Sections/SectionTrainingTable";

import trainingPageStyle from "assets/jss/material-kit-react/views/trainingPage.jsx";

class TrainingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pageInformation: null };
  }

  async componentDidMount() {
    const pageInformation = await getEntry("1oCLxSiGjpnhdnEMFVtf8L");
    this.setState({ pageInformation });
  }

  render() {
    const { classes, ...rest } = this.props;
    let brandStyle = {
      color: "#dd0000",
      fontWeight: "bold",
    };
    return (
      <Fragment>
        <Header
          brand={
            <Fragment>
              <span>SUNCITY</span>
              <span style={{ ...brandStyle }}>TRI</span>
            </Fragment>
          }
          leftLinks={<SocialLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          color="dark"
          changeColorOnScroll={{
            height: 700,
            color: "white",
          }}
          {...rest}
        />

        <Parallax image={require("assets/img/training.jpg")}>
          <div className={classes.ribbon}>
            <div className={classes.container}>
              <GridContainer
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0px 20px",
                }}
              >
                <GridItem md={4}>
                  <div className={classes.brand}>
                    <h1 className={classes.title}>
                      {this.state.pageInformation?.title}
                    </h1>
                  </div>
                </GridItem>
                <GridItem md={8}>
                  <div className={classes.brand}>
                    <h3 className={classes.subtitle}>
                      {this.state.pageInformation?.description}
                    </h3>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </Parallax>
        <SectionTrainingTable />
        <Footer />
      </Fragment>
    );
  }
}

TrainingPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(trainingPageStyle)(TrainingPage);

/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.7.0";

// pages for this product
import TrainingPage from "views/TrainingPage/TrainingPage.jsx";
// import JuniorsPage from "views/JuniorsPage/JuniorsPage.jsx";
import EventsPage from "views/EventsPage/EventsPage.jsx";
import ResultsPage from "views/ResultsPage/ResultsPage.jsx";
import TeamPage from "views/TeamPage/TeamPage.jsx";
import Components from "views/Components/Components.jsx";
import NotFound from "views/ErrorPages/NotFoundPage.jsx";
var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/training" component={TrainingPage} />
      {/* <Route path="/juniors" component={JuniorsPage} /> */}
      <Route path="/events" component={EventsPage} />
      <Route path="/results" component={ResultsPage} />
      <Route path="/team" component={TeamPage} />
      <Route path="/" exact component={Components} />
      <Route component={NotFound} status={404} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

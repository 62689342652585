import React, { Fragment } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SocialLinks from "components/Header/SocialLinks.jsx";

import SectionMeetTheCoaches from "views/Components/Sections/SectionMeetTheCoaches.jsx";

import teamPageStyle from "assets/jss/material-kit-react/views/teamPage.jsx";

class TeamPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    let brandStyle = {
      color: "#dd0000",
      fontWeight: "bold"
    };
    return (
      <Fragment>
        <Header
          brand={
            <Fragment>
              <span>SUNCITY</span>
              <span style={{ ...brandStyle }}>TRI</span>
            </Fragment>
          }
          leftLinks={<SocialLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          color="dark"
          changeColorOnScroll={{
            height: 1,
            color: "white"
          }}
          {...rest}
        />

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12}>
              <div className={classNames(classes.main, classes.mainRaised)}>
                <SectionMeetTheCoaches />
              </div>
            </GridItem>
          </GridContainer>
        </div>

        <Footer />
      </Fragment>
    );
  }
}

TeamPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(teamPageStyle)(TeamPage);

import React, { Fragment } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Coach from "components/Coach/Coach.jsx";

import { getEntry, getEntries } from "lib/api";

import aboutUsStyle from "assets/jss/material-kit-react/views/componentsSections/meetTheCoachesStyle.jsx";

class SectionMeetTheCoaches extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pageInformation: null };
  }

  async componentDidMount() {
    const pageInformation = await getEntry("54Iz6z0Aom1r5tH8T12svU");
    const coaches = (await getEntries({
      content_type: "teamMember",
    }))
      .reverse()
      .sort((a, b) => b.priority - a.priority);
    this.setState({ pageInformation, coaches });
  }

  render() {
    const { classes } = this.props;
    const { coaches } = this.state;

    const sections = [];
    for (let i = 0; i < coaches?.length; i += 3) {
      const sectionItems = [];
      for (let j = i; j < i + 3 && j < coaches?.length; j++) {
        const coach = coaches[j];
        sectionItems.push(
          <GridItem
            key={`coach-${j}`}
            xs={12}
            sm={12}
            md={4}
            className={classes.itemGrid}
          >
            <Coach
              coachName={coach?.name}
              title={coach?.role}
              imageSource={coach?.image.url}
              involvementYear={coach?.triathlonStartYear}
              favouriteKit={coach?.favouriteKit}
              topTip={coach?.topTip}
              favouritePastTime={coach?.favouritePastime}
              favouriteDiscipline={coach?.favouriteDiscipline}
            />
          </GridItem>
        );
      }

      sections.push(
        <div key={i} className={classes.sections}>
          <div className={classes.container}>
            <GridContainer>{sectionItems}</GridContainer>
          </div>
        </div>
      );
    }

    return (
      <Fragment>
        <div className={classes.sections}>
          <div className={classes.container}>
            <div className={classes.title}>
              <h2>{this.state.pageInformation?.title}</h2>
            </div>
            <div>
              <GridContainer justify="center">
                <GridItem xs={6}>
                  {this.state.pageInformation?.description}
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>

        {sections}
      </Fragment>
    );
  }
}

SectionMeetTheCoaches.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(aboutUsStyle)(SectionMeetTheCoaches);

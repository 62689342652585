import { container, title } from "assets/jss/material-kit-react.jsx";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";
import { primaryColor } from "assets/jss/material-kit-react.jsx";

const duathlonStyle = {
  sections: {
    padding: "20px 0",
    display: "flex",
    "flex-grow": 1,
  },
  container: {
    ...container,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    ...title,
    marginTop: "-15px",
    minHeight: "32px",
    textDecoration: "none",
  },
  ...imagesStyle,
  buttons: {
    padding: "15px",
  },
  duathlonBody: {
    backgroundColor: "rgba(238, 238, 238, 1)",
    //marginTop: "30px",
    margin: "15px -15px",
    padding: "15px",
    flex: 1,
    "flex-grow": 1,
    //paddingBottom: "15px"
  },
  duathlonDate: {
    color: primaryColor,
  },
  duathlonText: {
    flex: 1,
  },
};

export default duathlonStyle;

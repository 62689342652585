import {
  container,
  card,
  cardTitle,
  title
} from "assets/jss/material-kit-react.jsx";
import { primaryColor } from "assets/jss/material-kit-react.jsx";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";
const meetTheCoachesStyle = {
  meetTheCoaches: {},
  sections: {
    // padding: "20px 0",
    textAlign: "center",
    "&:nth-child(odd)": {
      background: "#FFFFFF"
      // boxShadow: "0px 0px 3px 0px rgba(50,50,50,1)"
    },
    "&:nth-child(even)": {
      background: "#EEEEEE"
      // boxShadow: "0px 5px 3px 0px rgba(50,50,50,1)"
    }
  },

  container,
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  bordered: {
    border: `2px solid ${primaryColor}`,
    marginLeft: "auto",
    marginRight: "auto"
  },
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex"
  },
  card: {
    ...card,
    display: "flex",
    flex: 1
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d"
  },
  description: {
    color: "#999"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999",
    "&.fa-twitter": {
      color: "#1ea1f3"
    },
    "&.fa-facebook": {
      color: "#3b5998"
    },
    "&.fa-instagram": {
      color: "#dd2a7b"
    },
    "&.fa-strava": {
      color: "#fc4c02"
    }
  },
  margin5: {
    margin: "5px"
  }
};

export default meetTheCoachesStyle;
